import React from "react"
import Product from "../product";
import Blank from "../assets/blank.svg";

const Category = (props) => {
	return (
		<div className="mb-6">
			<p className="has-text-white is-size-5 has-text-weight-bold mb-2">
				{props.data.name}
			</p>

			<div className="columns is-variable is-1">
				{props.data.products && props.data.products.length > 0 ? (
					props.data.products
						.filter((product) => {
							if (
								JSON.stringify(product)
									.toLowerCase()
									.includes(props.search.toLowerCase())
							) {
								return product;
							}
						})
						.map((product) => {
							return (
								<div className="column is-6" key={product.id}>
									<Product data={product} />
								</div>
							);
						})
				) : (
					<div className="column is-12">
						<div className="card">
							<div className="card-content">
								<p className="is-flex is-uppercase	is-size-7" style={{ alignItems: "center" }}>
									<img src={Blank} style={{ width: "120px" }} />
									Sorry, We're out of {props.data.name}! :(
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Category;
