import React from "react"
import Category from "../category";

const Menu = (props) => {
	const data = props.data;
	return (
		<div>
			{data
				? data.map((category) => {
						return (
							<Category
								key={category.node.id}
								data={category.node}
								search={props.search}
							/>
						);
				  })
				: ""}
		</div>
	);
};

export default Menu;
