import React, { useState } from "react";
import styled from "styled-components";
import ProductModal from "./productModal";

const Product = (props) => {
	const [modal, setModal] = useState(false);
	return (
		<>
			<CustomCard className="card" onClick={() => setModal(true)}>
				<div className="card-content">
					<div className="columns mx-0 my-0 is-mobile is-vcentered">
						<StyledPrice className="column is-3  has-text-centered has-background-warning-light">
							<p className="is-size-4 has-text-weight-bold has-text-warning-dark">
								{props.data.price}
							</p>
							<p
								className="has-text-warning-dark"
								style={{ marginTop: "-6px" }}
							>
								Lei
							</p>
						</StyledPrice>
						<div className="column ">
							<p className="has-text-weight-bold">{props.data.name}</p>
							{props.data.description ? (
								<p className="my-1 is-size-7">
									{props.data.description.length > 80
										? props.data.description.substring(0, 77) + "..."
										: props.data.description}
								</p>
							) : (
								""
							)}

							<div className="tags">
								{props.data.quantity ? (
									<span className="tag is-warning is-light">
										{props.data.quantity}
									</span>
								) : (
									""
								)}

								{props.data.percentage ? (
									<span className="tag is-success is-light">
										{props.data.percentage}%
									</span>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</CustomCard>

			{modal ? <ProductModal data={props.data} setModal={setModal} /> : ""}
		</>
	);
};

export default Product;

const StyledPrice = styled.div`
	// min-height: 64px;
	// background: #FCEED7;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
`;

const CustomCard = styled.div`
	height: 100%;
	.card-content {
		height: 100%;
		& > .columns {
			align-items: stretch;
			height: 100%;
		}
	}
`;
