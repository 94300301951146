import React from "react";
import styled from "styled-components";

const ProductModal = (props) => {
	return (
		<div className="modal is-active">
			<div
				className="modal-background"
				onClick={() => props.setModal(false)}
			></div>
			<div className="modal-content">
				<div className="card">
					{/* <div className="card-image">
						<figure className="image is-4by3">
							<img
								src="https://bulma.io/images/placeholders/1280x960.png"
								alt="Placeholder image"
							/>
						</figure>
					</div> */}
					<div className="card-content">
						<div className="columns mx-0 my-0 is-mobile is-vcentered">
							<StyledPrice className="column is-3  has-text-centered has-background-warning-light">
								<p className="is-size-4 has-text-weight-bold has-text-warning-dark">
									{props.data.price}
								</p>
								<p
									className="has-text-warning-dark"
									style={{ marginTop: "-6px" }}
								>
									Lei
								</p>
							</StyledPrice>
							<div className="column ">
								<p className="has-text-weight-bold">{props.data.name}</p>
								{props.data.description ? (
									<p className="my-1 is-size-7">{props.data.description}</p>
								) : (
									""
								)}

								<div className="tags">
									{props.data.quantity ? (
										<span className="tag is-warning is-light">
											{props.data.quantity}
										</span>
									) : (
										""
									)}

									{props.data.percentage ? (
										<span className="tag is-success is-light">
											{props.data.percentage}%
										</span>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>

					<footer className="card-footer">
						<button
							className="card-footer-item button is-dark is-radiusless"
							style={{ position: "sticky", bottom: "0" }}
							onClick={() => props.setModal(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
                className="is-16 is-white mr-1"
							>
								<path
									d="M 5.7070312 4.2929688 L 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 L 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 L 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 L 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 z"
								/>
							</svg>
							Close
						</button>
					</footer>
				</div>
			</div>
			<button
				className="modal-close is-large"
				aria-label="close"
				onClick={() => props.setModal(false)}
			></button>
		</div>
	);
};

export default ProductModal;

const StyledPrice = styled.div`
	// min-height: 64px;
	// background: #FCEED7;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
`;

const CustomCard = styled.div`
	height: 100%;
	.card-content {
		height: 100%;
		& > .columns {
			align-items: stretch;
			height: 100%;
		}
	}
`;
