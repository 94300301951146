import * as React from "react";
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Helmet from 'react-helmet';
import logo from "../assets/logo.jpg";
import Menu from "../menu";
import "../style/App.scss";

const IndexPage = () => {
	const [search, setSearch] = useState("");

	const data = useStaticQuery(query);

	return (
		<div className="App">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Meniu Beerwall Cafe</title>
				<link rel="canonical" href="https://beerwallcafe.ro" />
			</Helmet>
			<div className="container">
				<section className="section has-text-centered">
					<img src={logo} alt="" style={{ height: "80px" }} />
				</section>

				<section
					className="section has-text-centered pt-0 pb-0"
					style={{ position: "sticky", top: "8px", zIndex: "10" }}
				>
					<div className="field">
						<p
							className="control has-icons-left"
							style={{ width: "80%", margin: "auto" }}
						>
							<input
								className="input"
								type="text"
								placeholder="Search.."
								onChange={(e) => setSearch(e.target.value)}
							/>
							<span className="icon is-small is-left">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									className="is-16"
								>
									<path d="M 9 2 C 5.1458514 2 2 5.1458514 2 9 C 2 12.854149 5.1458514 16 9 16 C 10.747998 16 12.345009 15.348024 13.574219 14.28125 L 14 14.707031 L 14 16 L 20 22 L 22 20 L 16 14 L 14.707031 14 L 14.28125 13.574219 C 15.348024 12.345009 16 10.747998 16 9 C 16 5.1458514 12.854149 2 9 2 z M 9 4 C 11.773268 4 14 6.2267316 14 9 C 14 11.773268 11.773268 14 9 14 C 6.2267316 14 4 11.773268 4 9 C 4 6.2267316 6.2267316 4 9 4 z" />
								</svg>
							</span>
						</p>
					</div>
				</section>

				<section className="section">
					<Menu
						data={data.allStrapiCategory.edges.sort((a, b) =>
							a.node.order > b.node.order ? 1 : -1
						)}
						search={search}
					/>
				</section>
			</div>
		</div>
	);
};

const query = graphql`
	query {
		allStrapiCategory {
			edges {
				node {
					id
					name
					order
					products {
						id
						name
						price
						quantity
						percentage
						description
					}
				}
			}
		}
	}
`;

export default IndexPage;
